import React from "react";
import ReactDOM from "react-dom/client";
import "bulma/css/bulma.min.css";
import App from "./App";
import "./style/style.css";
import { BrowserRouter } from "react-router-dom";

import { UserProvider } from "./context/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <UserProvider>
            <App />
        </UserProvider>
    </BrowserRouter>
);
