import React from 'react'
import ReactPlayer from 'react-player'

function VideoPlayerModal(props) {
    return (
        <div className={`modal ${props.active && "is-active"}`}>
            <div className="modal-background" onClick={props.handleModal}></div>
            <div className="modal-card">
                <ReactPlayer controls={true} url={props.sourceUrl}/>
            </div>
        </div>
    )
}

export default VideoPlayerModal
