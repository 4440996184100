import React from 'react';
import {Routes, Route} from 'react-router-dom';

import MyTreeView from './MyTreeView';
import JsonPanel from './JsonPanel';

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MyTreeView />} />
            <Route path="/json-panel" element={<JsonPanel />} />
        </Routes>
    );
};

export default AdminRoutes;