import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const JsonPanel = ( props ) => {

    const navigate = useNavigate();

    const [token] = useContext(UserContext);

    const [fileName, setFileName] = useState("");

    const [lastModified, setLastModified] = useState("");

    const [fileUrl, setFileUrl] = useState("");

    const [firebaseUrl, setFirebaseUrl] = useState("");

    const [readyToImport, setReadyToImport] = useState(false);

    const [isValid, setIsValid] = useState(false);

    const [error, setError] = useState(null);

    const [newFileName, setNewFileName] = useState("");

    const [newFile, setNewFile] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [uploaded, setUploaded] = useState(false);


    const validateJson = (event) => {

        const file = event.target.files[0];

        setNewFileName(file.name);

        const reader = new FileReader();

        reader.onload = () => {
            const jsonData = reader.result;

            try {
                JSON.parse(jsonData);
                setIsValid(true);
                setError(null);
                setNewFile(file);
            } catch (error) {
                setIsValid(false);
                // setError(error.message);
                setError("FILE IS NOT VALID");
            }
        };

        reader.readAsText(file);
    }

    useEffect(() => {

        const getJsonFileData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };

            const response = await fetch("/storage/api/json_file_data", requestOptions);
            const data = await response.json();

            if (!response.ok) {
                console.log("THE ERROR");
                console.log(response);
            } else {
                return data;
            }
        };
        const setElements = async () => {
            const data = await getJsonFileData();
            if (data && data.file_name !== undefined) {
                setFileName(data.file_name);
                setLastModified(data.last_modified);
                setFileUrl(data.file_url);
                setFirebaseUrl(data.firebase_url);
            } else {
                setError("THERE ARE NO JSON FILES YET");
            }
        };

        setElements();
    }, [token, uploaded]);

    const downloadJson = () => {
        const requestOptions = {
            // mode: "no-cors",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        fetch("/storage/api/json_file", requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("download", fileName,);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
    }

    const cancelImport = () => {
        document.getElementById("jsonFileInput").value = "";
        setReadyToImport(false);
        setIsValid(false);
        setNewFileName("");
        setNewFile(null);
        setError(null);
    }

    const handleUploadFile = async (e) => {

        setIsLoading(true);

        const formData = new FormData();

        formData.append("file_obj", newFile, newFile.name);

        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        const response = await fetch("/storage/api/json_file", requestOptions);

        const responseData = await response.json();

        setIsLoading(false);

        if (!response.ok) {
            console.log("Error");
            console.log(response);
            setError(
                "THE ERROR"
            )
        } else {
            console.log(responseData);
            setUploaded(!uploaded);
            cancelImport();
        }
    }

    return (
        <div>
            <div>
                <button
                    id="go-back-btn"
                    className="button mb-2 mr-2 is-info is-light"
                    onClick={(event) => {navigate("/")}}>
                        Back
                </button>
            </div>
            <div style={{ display: isLoading ? 'flex' : 'none', zIndex: 999 }} className='loader-modal'>
                    <div className='loader-modal-content'>
                    <div className='loader'></div>
                    <div className='loader-modal-text'>Loading...</div>
                    </div>
            </div>
            <div>
                <button 
                    className="button mb-2 mr-2 is-info is-light"
                    onClick={(event) => {setReadyToImport(true)}}>
                    IMPORT JSON
                </button>
                <button 
                    className="button mb-2 mr-2 is-info is-light"
                    onClick={(event) => {downloadJson()}}>
                    EXPORT JSON
                </button>
            </div>
            <br />
            {readyToImport && 
                <div>
                    <div className="file">
                        <label className="file-label">
                            <input id="jsonFileInput" className="file-input" type="file" accept=".json" onChange={validateJson} />
                            <span className='file-cta'>
                                <span className='file-icon'>
                                    <i className="fa fa-upload"></i>
                                </span>
                                <span className='file-label'>
                                    Choose a file...
                                </span>
                            </span>
                            <span className='file-name'>
                                {newFileName}
                            </span>
                        </label>
                        <div>
                            <button 
                                className="button ml-5 mb-2 mr-2 is-light is-danger"
                                onClick={(event) => {cancelImport()}}>
                                CANCEL
                            </button>
                        </div>
                        {isValid && <div>
                            <button 
                                className="button mb-2 mr-2 is-light is-primary"
                                onClick={(event) => {handleUploadFile(event)}}>
                                UPLOAD JSON
                            </button>
                        </div>}
                    </div>
                    <br/>
                </div>
            }
            {error && <div className="error-message">{error}</div>}
            {fileName && 
                <div>
                    <div className="column">
                        <i className="fa fa-file-code-o" aria-hidden="true"></i>&nbsp;&nbsp;
                        {fileName}
                    </div>
                    <div className="column">
                        <span>Last modified: {lastModified}</span>
                    </div>
                    <div className="column">
                        <span>Url: {fileUrl}</span>
                    </div>
                    <div className="column">
                        <span>Firebase Url: {firebaseUrl}</span>
                    </div>
                </div>
            }
        </div>
        
    );
};

export default JsonPanel;