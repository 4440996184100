import React, { useState, useEffect } from "react";
import CreateDirectoryModal from "./CreateDirectoryModal";
import FileUpload from "./FileUpload";
import DeleteElementModal from "./DeleteElementModal";

const CreateModal = ({ active, handleModal, modalType, context, elementId, item, currentElement, setLoadingState }) => {

    const [fileList, setFileList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [dirName, setDirName] = useState("");

    const [convert, setConvert] = useState(false);


    const [token] = context;


    useEffect(() => {
        if (!active) {
            cleanModalFormData();
        }
    });

    const handleUploadFile = async (e) => {
        // e.preventDefault();
        setLoadingState(true);
        const formData = new FormData();
        let keys = getKeys(currentElement, "File Storage").reverse();

        // formData.append("file_obj", selectedFile, selectedFile.name);
        

        files.forEach((file, i) => {
            formData.append(`file_obj`, file, file.name);
        })
        // formData.append("parent_id", elementId);
        formData.append("keys", JSON.stringify(keys));

        formData.append("convert", convert);

        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        const response = await fetch("/storage/api/upload_file", requestOptions);
        const responseData = await response.json();

        setLoadingState(false);

        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
            setErrorMessage("The error occurred while uploading file");
        } else {
            handleModal(responseData);
            // return responseData;
        }
    };

    const handleCreateDirectory = async (e) => {
        // e.preventDefault();

        let keys = getKeys(currentElement, "File Storage");
        if (! Array.isArray(keys)) {
            setErrorMessage(keys);
            return;
        }
        setLoadingState(true);

        const requestOptions = {
            // method: "POST",
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                action: "create",
                keys: keys.reverse(),
                key: dirName,
            }),
        };

        // const response = await fetch("/storage/api/storage_directory", requestOptions);
        const response = await fetch("/storage/api/storage_elements/", requestOptions);
        const responseData = await response.json();

        setLoadingState(false);

        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
            setErrorMessage(responseData.message);
        } else {
            cleanModalFormData();
            handleModal(responseData);
            // return responseData;
        }
    };

    const cleanModalFormData = () => {
        setDirName("");
        setErrorMessage("");
    };


    const getKeys = (element, parentSelector) => {

        if (element.node.text === parentSelector) {
            if (!/^[a-zA-Z0-9.\-_]{1,255}$/.test(dirName)) {
                return "The bucket name shouldn't contain any whitespaces or special symbols.";
            }
            return [];
        } 

        let keys = [];
        keys.push(element.node.text);

        let p = element.node.parent;

        while (p.text !== parentSelector) {
            let o = p;
            keys.push(o.text);
            p = o.parent;
        }

        return keys;
    }

    const handleDeleteElement = async (e) => {
        let keys = getKeys(currentElement, "File Storage").reverse();
        let elementType = currentElement.node.children.length > 0 ? "folder" : "singleObject";
        setLoadingState(true);
        const requestOptions = {
            // method: "DELETE",
            method: "PATCH",
            body: JSON.stringify({
                action: "delete",
                keys: keys,
                element_type: elementType,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        console.log(keys);

        // const response = await fetch(`/storage/api//storage_elements/?element_id=${elementId}`, requestOptions);
        const response = await fetch(`/storage/api/storage_elements/`, requestOptions);
        const responseData = await response.json();

        setLoadingState(false);
        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
            setErrorMessage("The error occurred while deleting directory");
        } else {
            cleanModalFormData();
            handleModal(responseData);
        }
    };

    const files = fileList ? [...fileList] : [];

    return (
        <div className={`modal ${active && "is-active"}`}>
            <div className="modal-background" onClick={(e) => handleModal()}></div>
            <div className="modal-card">
                {modalType && modalType === "directory" && (
                    <CreateDirectoryModal
                        handleModal={handleModal}
                        handleCreateDirectory={(e) => handleCreateDirectory(e)}
                        dirName={dirName}
                        setDirName={setDirName}
                        errorMessage={errorMessage}
                    />
                )}
                {modalType && modalType === "element" && (
                    <FileUpload 
                    errorMessage={errorMessage}
                    handleModal={handleModal}
                    handleUploadFile={handleUploadFile}
                    setFileList={setFileList}
                    active={active}
                    setConvert={setConvert}
                    />
                )}
                {modalType && modalType === "delete" && (
                    <DeleteElementModal
                        item={item}
                        errorMessage={errorMessage}
                        handleModal={handleModal}
                        handleDeleteElement={handleDeleteElement}
                    />
                )}
            </div>
        </div>
    );
};

export default CreateModal;
