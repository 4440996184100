import React from "react";
import ReactDOM from "react-dom/client";
import TreeView from "devextreme-react/tree-view";
import "devextreme/dist/css/dx.light.css";
import { UserContext } from "../context/UserContext";
import CreateModal from "./CreateModals";
import ProductDetails from "./ProductDetails";
import ButtonsBlock from "./ButtonsBlock";
import VideoPlayerModal from "./VideoPlayerModal";

class MyTreeView extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            currentItem: {},
            activeModal: false,
            modalType: "directory",
            isMobile: false,
            isLoading: true,
            currentItemElement: null,
            sourceUrl: "",
            videoPlayerActive: false,
            opennedNodeKey: null,
            previewTop: "0px",
        };
        this.treeViewRef = React.createRef();
        this.selectItem = this.selectItem.bind(this);
        this.setActiveModal = this.setActiveModal.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.saveTreeViewInstance = this.saveTreeViewInstance.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.resetCurentItem = this.resetCurentItem.bind(this);
        this.setVideoPlayerActive = this.setVideoPlayerActive.bind(this);
        this.setSourceUrl = this.setSourceUrl.bind(this);
        this.closeVideoPlayerModal = this.closeVideoPlayerModal.bind(this);
        this.onItemExpanded = this.onItemExpanded.bind(this);
        this.expandNode = this.expandNode.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.setPreviewTop = this.setPreviewTop.bind(this);

    }

    componentDidMount() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.setState({
                isMobile: true,
            });
        }
        return this.setElements();
    }

    saveTreeViewInstance(e) {
        this.treeViewInstance = e.component;
    }

    getContentTree = async () => {
        const [token] = this.context;

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch("/storage/api/storage_elements", requestOptions);
        const data = await response.json();

        if (!response.ok) {
            console.log("THE ERROR");
            console.log(response);
        } else {
            return data;
        }
    };

    setElements() {
        this.getContentTree().then((response) => {
            this.setState({
                items: response,
                currentItem: { ...this.state.items[0] },
                isLoading: false,
            });
        });
    }

    onUpdated() {
        if (this.state.opennedNodeKey) {
            this.expandNode(this.state.opennedNodeKey);
        }
        this.setLoading(false);
    }

    updateElements() {
        this.setLoading(true);
        this.getContentTree().then((response) => {
            this.setState({
                items: response,
                currentItem: { ...this.state.items[0] },
            }, this.onUpdated);
        });
    }

    setActiveModal(event, modalType) {
        this.setState({
            activeModal: true,
            modalType: modalType,
            isLoading: false,
        });
    }

    async handleModal(objectCreated = false) {
        this.setState({
            activeModal: false,
        });
        if (objectCreated) {
            this.updateElements();
        }
    }

    setLoading(state) {
        this.setState({
            isLoading: state,
        })
    };

    resetCurentItem() {
        this.setState({
            currentItem: {}
        });
    }

    setVideoPlayerActive() {
        this.setState({
            videoPlayerActive: true,
        })
    }

    setSourceUrl(url) {
        this.setState({
            sourceUrl: url,
        })
    }

    closeVideoPlayerModal() {
        this.setState({
            videoPlayerActive: false,
            sourceUrl: ""
        })
    }

    onItemExpanded(e) {
        this.setState({
            opennedNodeKey: e.node.key,
        });
    }

    setPreviewTop(offset) {
        const {scrollY} = window;
        const previewTop = scrollY + offset;
        this.setState({
            previewTop: `${previewTop}px`
        });
    }

    render() {
        const { currentItem } = this.state;
        const { currentItemElement } = this.state;
        return (
            <>
                <div>
                    <button
                        id="json-panel"
                        className="button mb-2 mr-2 is-info is-light"
                        onClick={(event) => {document.location.href += "json-panel"}}>
                        JSON
                    </button>
                </div>
                <div style={{ display: this.state.isLoading ? 'flex' : 'none', zIndex: 999 }} className='loader-modal'>
                    <div className='loader-modal-content'>
                    <div className='loader'></div>
                    <div className='loader-modal-text'>Loading...</div>
                    </div>
                </div>
                <VideoPlayerModal 
                    active={this.state.videoPlayerActive}
                    sourceUrl={this.state.sourceUrl}
                    handleModal={this.closeVideoPlayerModal}
                />
                <CreateModal
                    active={this.state.activeModal}
                    handleModal={this.handleModal}
                    modalType={this.state.modalType}
                    context={this.context}
                    elementId={currentItem.id}
                    item={currentItem}
                    currentElement={currentItemElement}
                    setLoadingState={this.setLoading}
                />
                <div className="form">
                    <div>
                        <div className="columns">
                            <div className="column">
                                <TreeView
                                    id="data-treeview"
                                    itemRender={this.renderTreeView}
                                    dataSource={this.state.items}
                                    width={this.state.isMobile ? 320 : 700}
                                    onItemClick={(e) => this.selectItem(e)}
                                    onInitialized={this.saveTreeViewInstance}
                                    expandedExpr="expanded"
                                    searchEnabled={true}
                                    keyExpr="url"
                                    onItemExpanded={this.onItemExpanded}
                                    ref={this.treeViewRef}
                                    // showCheckBoxesMode="normal"
                                />
                            </div>
                            {currentItem.text && currentItem.url && (
                                <>
                                    <div className="column">
                                        <div id="product-details" style={{position: "absolute", top: this.state.previewTop}}>
                                            <ProductDetails 
                                                currentItem={currentItem} 
                                                resetCurentItem={this.resetCurentItem}
                                                setVideoPlayerActive={this.setVideoPlayerActive}
                                                setSourceUrl={this.setSourceUrl}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div></div>
                    </div>
                </div>
            </>
        );
    }

    selectItem(e) {

        this.setState({
            currentItem: { ...e.itemData },
            currentItemElement: e,
        });
        let oldBtnsBlock = document.querySelector("div.btnsBlock");
        if (oldBtnsBlock) {
            oldBtnsBlock.parentNode.removeChild(oldBtnsBlock);
        }
        const btnsBlock = document.createElement("div");
        btnsBlock.classList.add("btnsBlock");
        ReactDOM.createRoot(btnsBlock).render(
            <ButtonsBlock currentItem={e.itemData} setActiveModal={this.setActiveModal} />
        );
        e.itemElement.parentNode.insertBefore(btnsBlock, e.itemElement.parentNode.childNodes[1]);
        this.setPreviewTop(e.itemElement.parentNode.childNodes[0].getBoundingClientRect().top);
    }

    renderTreeView(item) {
        if (! item.url || item.text === "File Storage") {
            return (
                <div>
                    <i className="fa fa-folder-o" aria-hidden="true"></i>&nbsp;&nbsp;
                    {item.text}
                </div>
                
            )
        } else if (item.url && item.text.endsWith(".zip")) {
            return (
                <>
                    <div className="column">
                        <i className="fa fa-file-archive-o" aria-hidden="true"></i>&nbsp;&nbsp;
                        {item.text}
                    </div>
                    <div className="column">
                        <span>Last modified: {item.last_modified}</span>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="column">
                        <i className="fa fa-file-image-o" aria-hidden="true"></i>&nbsp;&nbsp;
                        {item.text}
                    </div>
                    <div className="column">
                        <span>Last modified: {item.last_modified}</span>
                    </div>
                </>
            )
        }
    }

    expandNode(key) {
        this.treeView.expandItem(key);
    }

    get treeView() {
        return this.treeViewRef.current.instance;
    }
}

export default MyTreeView;
