import ErrorMessage from "./ErrorMessage";

const CreateDirectoryModal = ({ handleModal, handleCreateDirectory, dirName, setDirName, errorMessage }) => {
    return (
        <>
            <header className="modal-card-head has-background-primary-light">
                <h1 className="modal-card-title">Create directory</h1>
            </header>
            <section className="modal-card-body">
                <div className="field">
                    <div className="control">
                        <label className="label">Directory name:</label>
                        <input
                            type="text"
                            className="input"
                            value={dirName}
                            onChange={(e) => setDirName(e.target.value)}
                        />
                    </div>
                </div>
            </section>
            <ErrorMessage message={errorMessage} />
            <footer className="modal-card-foot has-background-primary-light">
                <button className="button is-info createElementBtn" onClick={(e) => handleCreateDirectory()}>
                    Create
                </button>
                <button className="button" style={{ cursor: "pointer" }} onClick={(e) => handleModal()}>
                    Cancel
                </button>
            </footer>
        </>
    );
};

export default CreateDirectoryModal;
