const ButtonsBlock = ({ currentItem, setActiveModal }) => {
    return (
        <div className="mt-2 mb-2">
            {currentItem.text && !currentItem.url && (
                <>
                    <div>
                        <button
                            id={`directory-${currentItem.id}`}
                            className="button mb-2 mr-2 is-info is-light createDirectory"
                            onClick={(event) => setActiveModal(event, "directory")}
                        >
                            {currentItem.text !== "File Storage" ? "Create New Folder" : "Create New Bucket"}
                        </button>
                        {currentItem.text !== "File Storage" && (
                            <>
                            <button
                                id={`element-${currentItem.id}`}
                                className="button mb-2 mr-2 is-info is-light createElement"
                                onClick={(event) => setActiveModal(event, "element")}
                            >
                                Upload file
                            </button>
                            {!currentItem.bucket && (
                                <button
                                    id={`delete-${currentItem.id}`}
                                    className="button mb-2 mr-2 is-danger is-light deleteElement"
                                    onClick={(event) => setActiveModal(event, "delete")}
                                >
                                    Delete {currentItem.text}
                                </button>
                            )}
                            </>
                        )}
                    </div>
                </>
            )}
            {currentItem.text && currentItem.url && (
                <>
                    <div>
                        <button
                            id={`delete-${currentItem.id}`}
                            className="button mb-2 mr-2 is-danger is-light deleteElement"
                            onClick={(event) => setActiveModal(event, "delete")}
                        >
                            Delete {currentItem.text}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ButtonsBlock;
